<template>
  <!-- <section> -->
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col>
            <div>Daftar History</div>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text class="blog-content-truncate">
        <div class="row">
          <div class="my-1 col">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </div>
          <div class="my-1 col">
            <b-form-group label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search......" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="table-responsive">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="fields"
              :items="data"
            >
              <!-- A virtual column -->
              <template #cell(name)="data">
                {{ data.value }}
              </template>
              <template #cell(school_origin_info)="data">
                {{ data.value.nama_sekolah ? data.value.nama_sekolah : data.item.school_origin }}
              </template>
              <template #cell(school_accreditation)="data">
                {{ data.value }}
              </template>

              <template #cell(created_at)="data">
                {{ humanDate(data.value) }}
              </template>

              <template #cell(detail)="data">
                <b-button size="sm" variant="outline-dark" @click="detail(data)"><feather-icon icon="EyeIcon" /></b-button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="data.length" :per-page="perPage" align="center" size="sm" class="my-0" />
          </div>
        </div>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BCardText, BCardTitle, BCardBody, BPagination, BProgress, BBadge, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["data"],
  data() {
    return {
      fields: [
        { key: "name", label: "Nama", sortable: true },
        { key: "school_origin_info", label: "Asal Sekolah ", sortable: true },
        { key: "school_accreditation", label: "Akreditasi Sekolah", sortable: true },
        { key: "created_at", label: "Tanggal Daftar", sortable: true },
        { key: "detail", label: "" },
      ],
      total: [],
      record: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      filterType: null,
      optType: [
        { text: "Paket", value: "course" },
        { text: "Ebook", value: "ebook" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      search: null,
      idd: null,
    };
  },
  watch: {
    filterType(val) {
      this.$emit("load", val);
    },
  },
  computed: {},
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detail(data) {
      this.$store.commit("snbp/SET_DETAIL", data.item);
      this.$router.push({ name: "snbp-detail" });
    },
  },
};
</script>

<style></style>
